/* istanbul ignore file */
import KatalLogger, { Level } from "@amzn/katal-logger";

const katalLoggerConfig = {
  url: "https://yu4e7rr3e1.execute-api.us-west-2.amazonaws.com/prod/v1/log",
  logThreshold: Level.INFO,
  maxLogLineSize: 10000,
  logToConsole: "alpha".toLowerCase() !== "prod",
  context: {
    'User-Agent': window.navigator.userAgent,
  }
};

export const logger = new KatalLogger(katalLoggerConfig);
logger.addErrorListener();
