import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Accordion, Button, Card } from "@amzn/alchemy-components-react";
import Excel, { Workbook } from "exceljs";
import { logger } from "src/logger";
import { AlertType, PageType, TenantID } from "src/common/enums";
import { BreadCrumbs } from 'src/components/breadcrumb';
import { PageProps } from "src/pages/page-interface";
import { AlertBar } from "src/components/alert-bar";
import ValidationResultRow from "src/components/validation-result-row";
import {
    COLUMN_NAME_EXCEL_ROW,
    DATA_END_EXCEL_ROW,
    DATA_SHEET_NAME,
    DATA_START_EXCEL_ROW,
    ENTERPRISE_SCOPE,
    MAX_ONBOARD_USER_COUNT,
} from "src/common/constants";
import { DataInputField, constructDataInputFields, TemplateGenerator } from "src/pages/onboard/template-generator";
import { useMutation } from "@apollo/client";
import { BULK_ONBOARD_USER_MUTATION } from "src/common/gql-operations";
import { BulkOnboardUserData, BulkOnboardUserVariables } from "src/models/bulk-onboard-user";
import * as KatalMetrics from "@amzn/katal-metrics";
import initialMetricsPublisher from "src/metrics";
import { UserInfo } from "src/models/user-info";
import { ValidationResult } from "src/models/validation-result";
import { FallbackSpinner } from "src/components/fallback-spinner";
import { useAuth } from "src/components/auth/auth-provider";
import { loadExcelFile, downloadExcelFile, columnLetterToNumber } from "src/common/excel-util";
import { ValidationError } from "src/models/validation-error";
import { getTranslatedErrorMsg } from "src/common/util";

const cloudWatchDimensions = [
    new KatalMetrics.Metric.String('page', 'bulk-onboard'),
]

// @ts-ignore
const additionalMetricsContext = new KatalMetrics.Context({cloudWatchDimensions});
const graphqlClientMetricsPublisher =
    initialMetricsPublisher.newChildActionPublisherForMethod('graphql-client', additionalMetricsContext);

export const BulkOnboard = (props: PageProps) => {
    const auth = useAuth();
    const {t} = useTranslation();

    const inputRef = useRef<HTMLInputElement>(null);
    const [file, setFile] = useState<File>();

    const [alertBarType, setAlertBarType] = useState<AlertType>();
    const [alertBarHeader, setAlertBarHeader] = useState<string>();
    const [alertBarMsg, setAlertBarMsg] = useState<string>();

    const [instructionsExpanded, setInstructionsExpanded] = useState<boolean>(true);
    const [validationResults, setValidationResults] = useState<ValidationResult[]>();
    const [commonValidationErrors, setCommonValidationErrors] = useState<ValidationError[]>();

    const [getPrincipalScopesLoading, setGetPrincipalScopesLoading] = useState<boolean>(true);
    const [scopeOptions, setScopeOptions] = useState<any[]>([]);
    const [dataInputFields, setDataInputFields] = useState<DataInputField[]>([]);

    // Mutation for sending a Bulk Onboard User request to the FMUI GraphQL back-end
    const [bulkOnboardUser, {
        loading
    }] =
        useMutation<BulkOnboardUserData, BulkOnboardUserVariables>(BULK_ONBOARD_USER_MUTATION, {
            onCompleted: data => {
                logger.info("Retrieved data from bulkOnboardUser");

                setValidationResults(data.bulkOnboardUser.validationResults);
                setCommonValidationErrors(data.bulkOnboardUser.commonValidationErrors);

                if (!data.bulkOnboardUser.workflowId) {
                    const validationResults = data.bulkOnboardUser.validationResults;
                    logger.info("Bulk User Onboard data common validation failures : " + data.bulkOnboardUser.commonValidationErrors);
                    validationResults.forEach((validationResult) => {
                        logger.info("Bulk User Onboard data failed validation: " + validationResult.errors);
                    })
                    graphqlClientMetricsPublisher.publishCounterMonitor('bulk-onboard-user.VALIDATION_ERROR', 1);
                    showErrorAlert(
                        t('submission-failed'),
                        t('please-correct-the-validation-errors-and-resubmit-the-file'));
                } else {
                    logger.info("Bulk User Onboard workflow execution started: " + data.bulkOnboardUser.workflowId);
                    graphqlClientMetricsPublisher.publishCounterMonitor('bulk-onboard-user.SUCCESS', 1);
                    showSuccessAlert(t('success'), t('bulk-user-onboard-data-submitted'));
                }
            },
            onError: error => {
                logger.error("Call to bulkOnboardUser failed!", error);
                graphqlClientMetricsPublisher.publishCounterMonitor('bulk-onboard-user.ERROR', 1);
                showErrorAlert(t('submission-failed'), error.message);
            }
        });

    const populateScopes = async () => {
        const scopes = await auth.getScopes();

        if (scopes && scopes.length > 0) {
            scopes
                // Enterprise should not be an option for onboarding of anyone
                .filter(scope => scope !== ENTERPRISE_SCOPE)
                .forEach(scope => scopeOptions.push(scope));
        } else {
            showErrorAlert(t('error'), t('failed-to-get-sites-user-can-access'));
        }

        setScopeOptions(scopeOptions);
        setGetPrincipalScopesLoading(false);
    }

    /**
     * Sets the active page in the navbar (triggered on page load).
     */
    useEffect(() => {
        props.setActivePage(PageType.ONBOARD);
        populateScopes();
    }, []);


    useEffect(() => {
        setDataInputFields(constructDataInputFields(t, scopeOptions));
    }, [scopeOptions]);

    /**
     * Calls to bulk onboard users.
     */
    const callBulkOnboardUser = (users: UserInfo[]) => {
        setValidationResults([]); // reset results

        logger.info(`Calling bulkOnboardUser for ${users.length} users`);
        graphqlClientMetricsPublisher.publishCounterMonitor('bulk-onboard-user.INVOCATION', 1);
        bulkOnboardUser({
            variables: {
                bulkOnboardUserInput: {
                    tenantId: TenantID.AFTX,
                    users: users
                }
            },
        });
    }

    /**
     * Shows a success via the AlertBar.
     *
     * @param header the header for the AlertBar.
     * @param msg the message for the AlertBar.
     */
    const showSuccessAlert = (header: string, msg: string) => {
        setAlertBarType(AlertType.success);
        setAlertBarHeader(header)
        setAlertBarMsg(msg);
    }

    /**
     * Shows a warning via the AlertBar.
     *
     * @param header the header for the AlertBar.
     * @param msg the message for the AlertBar.
     */
    const showWarningAlert = (header: string, msg: string) => {
        setAlertBarType(AlertType.warning);
        setAlertBarHeader(header)
        setAlertBarMsg(msg);
    }

    /**
     * Shows an error via the AlertBar.
     *
     * @param header the header for the AlertBar.
     * @param msg the message for the AlertBar.
     */
    const showErrorAlert = (header: string, msg: string) => {
        setAlertBarType(AlertType.error);
        setAlertBarHeader(header)
        setAlertBarMsg(msg);
    }

    /**
     * Resets the AlertBar since it's re-usable.
     */
    const resetAlertBar = () => {
        setAlertBarType(undefined);
        setAlertBarHeader(undefined);
        setAlertBarMsg(undefined);
    }

    /**
     * Opens the file selection dialog window.
     */
    const openFileSelectionDialogWindow = () => {
        // Click on hidden input to trigger file section dialog window to open
        // NOTE: Using hidden input element to be able to customize look-and-feel of file upload input element since
        // Alchemy doesn't provide out-of-the-box component and HTML default file input is not consistent with styling
        // of rest of Alchemy custom components
        inputRef.current!.click();
    }

    /**
     * Stores the file selection.
     * @param e the event.
     */
    const storeFileSelectionChoice = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    }

    /**
     * Handles the event when the 'Submit' button is clicked.
     */
    const handleSubmit = async () => {
        logger.info(`Initiated Bulk Onboarding request`)
        if (!file) {
            showWarningAlert(t('no-file-selected'), t('please-select-a-file-and-try-again'));
            return;
        }

        try {
            setInstructionsExpanded(false);
            const users = await processUploadedFile(file);
            callBulkOnboardUser(users);
        } catch (e) {
            logger.error('Failed to submit bulk user onboard data!', e as Error);
        }
    }

    /**
     * Processes the uploaded file by validating it and parsing the bulk user onboard data from it.
     */
    const processUploadedFile = async (file: File) => {
        const data = await file.arrayBuffer();
        const workbook = new Excel.Workbook();
        await workbook.xlsx.load(data);

        validateSheets(workbook);

        const {headerRow, dataRows} = parseData(workbook);
        validateRows([headerRow].concat(dataRows));
        validateColumns(headerRow);
        return buildUserInfoObjects(dataRows);
    }

    /**
     * Validates that the required sheet exists in the workbook.
     *
     * @param workbook the workbook.
     */
    const validateSheets = (workbook: Workbook) => {
        const sheetNames: string[] = workbook.worksheets.map(sheet => sheet.name);

        if (!sheetNames.includes(DATA_SHEET_NAME)) {
            showErrorAlert(
                t('submission-failed'),
                t('workbook-is-missing-sheet', {
                    sheetName: DATA_SHEET_NAME
                })
            );
            throw new Error(`Workbook is missing sheet: ${DATA_SHEET_NAME}`);
        }
    }

    /**
     * Validates that the required columns exist in the worksheet.
     *
     * @param row the row to validate.
     */
    const validateColumns = (row: string[]) => {
        dataInputFields.forEach((dataFieldDefinition: DataInputField, i: number) => {
            const expectedColumnName = dataFieldDefinition.header;
            console.log(`Validating column: ${i} expected header: ${expectedColumnName} actual header: ${row[i]}`)

            // Check if column is missing or misordered
            if (!row.includes(expectedColumnName) || row[i] !== expectedColumnName) {
                showErrorAlert(
                    t('submission-failed'),
                    t('sheet-missing-or-misordered-columns', {
                        sheetName: DATA_SHEET_NAME,
                    })
                );
                throw new Error('Sheet has missing or misordered columns!');
            }
        });
    }

    /**
     * Validates that at least 1 row with onboarding data exists in the worksheet and that the max number of rows
     * allowed isn't exceeded.
     *
     * @param rows the parsed single header row and input data rows from the Excel workbook.
     */
    const validateRows = (rows: string[][]) => {
        logger.info(`Number of excel rows to be onboarded: ${rows.length}`);

        // The first row is the data's header row
        if (rows.length <= 1) {
            showErrorAlert(
                t('submission-failed'),
                t('sheet-missing-rows', {
                    sheetName: DATA_SHEET_NAME
                })
            );
            throw new Error('Sheet is missing rows!');
        }
        // We add one because the header row is included, so we can validate the column ordering later
        else if (rows.length > MAX_ONBOARD_USER_COUNT + 1) {
            showErrorAlert(
                t('submission-failed'),
                t('sheet-too-many-rows', {
                    sheetName: DATA_SHEET_NAME,
                    maxOnboardUserCount: MAX_ONBOARD_USER_COUNT
                })
            );
            const errorMsg = `Sheet has too many rows! This can be because more than ${MAX_ONBOARD_USER_COUNT} employees are in the 
                data sheet. Or there is data below row '${DATA_END_EXCEL_ROW}'. 
                Delete all data below row '${DATA_END_EXCEL_ROW}' and try again`;
            logger.error(errorMsg);
            throw new Error(errorMsg);
        }
    }

    interface ParsedData {
        headerRow: string[],
        dataRows: string[][],
    }

    /**
     * Parses the bulk user onboard data, both the header row and subsequent data rows, from the Excel workbook.
     *
     * @param workbook the workbook to parse.
     * @return {@link ParsedData}
     */
    const parseData = (workbook: Workbook): ParsedData => {
        const worksheet = workbook.getWorksheet(DATA_SHEET_NAME);
        let headerRow: string[] = [];
        let dataRows: string[][] = [];

        // Iterate over each non-empty row (up to the max row index + 1) and store cell data (up to last column index)
        // Note: Allow 'rows' to store 1 over max in order to perform validation in validateRows()
        worksheet.eachRow({includeEmpty: false}, (row, rowNumber) => {
            logger.info(`Excel RowNumber being parsed: '${rowNumber}'`);
            if (rowNumber < COLUMN_NAME_EXCEL_ROW) {
                logger.info(`Skip parsing the data in the label Excel row: ${rowNumber}`);
                return
            } else if (rowNumber === COLUMN_NAME_EXCEL_ROW) {
                logger.info(`Parse the column header row to later validate the rows are not misordered! Row: ${rowNumber}`)
                for (let i = 1; i <= dataInputFields.length; i++) {
                    headerRow.push(getCellValue(row.getCell(i), false));  // We only want the text of the cell
                }
            } else {
                let cells: string[] = [];
                for (let i = 1; i <= dataInputFields.length; i++) {
                    cells.push(getCellValue(row.getCell(i), true));
                }
                dataRows.push(cells);
            }
        });

        return {headerRow, dataRows};
    }

    /**
     * Gets the cell's value as a string.
     *
     * @param cell the cell from which to get the value.
     * @param transformData if the data is converted using the input field's additional cell value conversion function.
     */
    const getCellValue = (cell: Excel.Cell, transformData: boolean): string => {
        // Use cell.value to check if null since cell.text throws an exception if value is null
        let value = cell.value;
        if (!value) {
            // convert null to empty string
            return "";
        } else if (typeof value !== 'string') {
            // get text value of cell
            value = cell.text;
        }

        const inputField = dataInputFields
            .find(({columnLetter}: DataInputField) => parseInt(cell.col) === columnLetterToNumber(columnLetter));
        if (inputField != null && transformData && inputField?.additionalCellValueConversion != null) {
            logger.debug(`Additional parsing of value: '${value}'`);
            value = inputField.additionalCellValueConversion(value);
        }

        // @ts-ignore
        return value != null ? value : "";
    }

    /**
     * Transforms the row data from string[][] to UserInfo[].
     *
     * @param rows the rows to transform.
     */
    const buildUserInfoObjects = (rows: string[][]): UserInfo[] => {
        let users: UserInfo[] = [];

        rows.forEach((cells) => {
            let i = 0;

            users.push({
                scope: cells[i++],
                userType: cells[i++],
                aftx: {
                    roles: [
                        cells[i++] // For now, only one role is supported.
                    ]
                },
                startDate: cells[i++],
                endDate: cells[i++],
                legalName: {
                    firstName: cells[i++],
                    middleName: cells[i++],
                    lastName: cells[i++]
                },
                preferredName: {
                    firstName: cells[i++],
                    middleName: cells[i++],
                    lastName: cells[i++]
                },
                email: cells[i++],
                gender: cells[i++],
                dateOfBirth: cells[i++],
                nationalIdCountry: cells[i++],
                nationalIdType: cells[i++],
                nationalId: cells[i++],
                backgroundCheckVendorName: cells[i++],
                backgroundCheckReferenceNumber: cells[i++],
                // We don't have buy-in from business to allow background check exceptions, so leave this empty for now
                // TODO: Set this value once business approves (https://issues.amazon.com/issues/AFTI-1179)
                backgroundCheckExceptionId: ''
            });
        });

        logger.info(`Parsed ${users.length} users from the Excel data`);
        // Only one location can be onboarded at a time.
        logger.info(`Bulk Onboarding submission for location: ${users[0].scope}`);
        return users;
    }

    /**
     * Handles the event that occurs when a user clicks on the link to download the template.
     */
    const handleTemplateLinkClick = async () => {
        logger.info("Generating the Bulk Onboard Excel file.");
        const workbook = await loadExcelFile('fmui-bulk-onboard-template.xlsx');
        const templateGenerator = new TemplateGenerator(t, scopeOptions);
        templateGenerator.generateWorkbook(workbook);
        await downloadExcelFile(workbook, `fmui-bulk-onboard-template_${document.documentElement.lang}.xlsx`);
        logger.info(`Downloaded the Bulk Onboard Excel file for language: ${document.documentElement.lang}`);
    }

    return (
        <div id="bulk-onboard-page" className="container-fluid">
            <div className="row">
                <BreadCrumbs breadcrumbItems={[
                    {tag: t('onboard'), path: '/onboard'},
                    {tag: t('bulk'), path: '/onboard/bulk'}
                ]}/>
            </div>
            <div className="row mx-0 mb-0-5 b-background">
                <div className="col title m-0 py-1">{t('bulk-user-onboard')}</div>
            </div>
            <div className="b-background">
                <div className="row mx-0 mb-0-5 px-2 pt-2 pb-4">
                    <div className="col">
                        <Accordion id="instructions-accordion" header="Instructions" expanded={instructionsExpanded}>
                            <ol>
                                <li>
                                    {t('click')}
                                    <Link id="template-download-link"
                                          to="#"
                                          onClick={handleTemplateLinkClick}>
                                        &nbsp;{t('here')}&nbsp;
                                    </Link>
                                    {t('bulk-onboard-instructions-step-1')}
                                </li>
                                <li>
                                    {t('bulk-onboard-instructions-step-2', {
                                        maxOnboardUserCount: MAX_ONBOARD_USER_COUNT
                                    })}
                                </li>
                                <li>{t('bulk-onboard-instructions-step-3')}</li>
                                <li>
                                    {t('click-on-the')}
                                    <b> {t('select-file')} </b>
                                    {t('bulk-onboard-instructions-step-4')}
                                </li>
                                <li>
                                    {t('click-on-the')}
                                    <b> {t('submit')} </b>
                                    {t('bulk-onboard-instructions-step-5')}
                                </li>
                                <li>{t('bulk-onboard-instructions-step-6')}</li>
                                <li>{t('bulk-onboard-instructions-step-7')}</li>
                            </ol>
                        </Accordion>
                    </div>
                </div>
                <div className="row pb-4">
                    <div className="col">
                        <Card id="file-upload-card" className='mx-auto px-4'>
                            <div className="row pb-2">
                                <div className="col-auto pr-0">
                                    <Button
                                        id="select-file-button"
                                        label={t('select-file')}
                                        onClick={openFileSelectionDialogWindow}
                                    />
                                    <input ref={inputRef}
                                           id="input-select-file"
                                           type="file"
                                           onChange={storeFileSelectionChoice}
                                           accept=".xlsx"
                                           hidden/>
                                </div>
                                <div className="col">
                                    <p id="selected-file-p">{file ? file.name : t('no-file-selected')}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Button
                                        id="submit-button"
                                        className="c-success-alert"
                                        fullWidth={true}
                                        label={t('submit')}
                                        variant="action"
                                        onClick={handleSubmit}
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>

                {(commonValidationErrors && commonValidationErrors.length > 0) &&
                    <div className="row px-2 pb-2">
                        <div className="col">
                            <div className="table-responsive">
                                <table id="results-table-1" className="alchemy-table bordered">
                                    <thead>
                                    <tr>
                                        <th>{t('common-validation-errors')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>
                                            <Accordion
                                                header={`${commonValidationErrors.length} ${t('common-validation-errors-expand-to-view-details')}`}
                                                transparent
                                            >
                                                <ul>
                                                    {commonValidationErrors.map((error, i) => {
                                                        return (
                                                            <li key={i}>{getTranslatedErrorMsg(t, error)}</li>
                                                        );
                                                    })}
                                                </ul>
                                            </Accordion>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }

                {(validationResults && validationResults.length > 0) &&
                    <div className="row px-2 pb-2">
                        <div className="col">
                            <div className="table-responsive">
                                <table id="results-table-2" className="alchemy-table bordered">
                                    <thead>
                                    <tr>
                                        <th>{t('bulk-onboard-results-table-col-header-excel-row-number')}</th>
                                        <th>{t('bulk-onboard-results-table-col-header-validation-errors')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {validationResults.map((item, i) => {
                                        if (item.errors.length > 0) {
                                            return (
                                                <ValidationResultRow
                                                    key={i}
                                                    excelRowNum={DATA_START_EXCEL_ROW + i}
                                                    validationErrors={item.errors}
                                                />
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
            </div>
            {(loading || getPrincipalScopesLoading) &&
                <FallbackSpinner/>
            }
            {alertBarMsg &&
                <AlertBar
                    id="bulk-onboard-alert-bar"
                    result={alertBarType!}
                    dismissible={true}
                    header={alertBarHeader!}
                    message={alertBarMsg!}
                    reset={resetAlertBar}
                />
            }
        </div>
    );
}

export default BulkOnboard;