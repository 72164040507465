import {TokenProviderConfig} from "@amzn/fitjava-script-client";
import {
    AftxRole,
    BaseliningPermissionOperation,
    GenderType,
    NationalIdCountry,
    NationalIdType,
    UserType
} from "src/common/enums";

export const ENTERPRISE_SCOPE = "enterprise"
export const ENTERPRISE_SCOPE_OPTION = {
    label: ENTERPRISE_SCOPE,
    value: ENTERPRISE_SCOPE,
}

// Filled in from webpack
export const REGION = 'us-west-2'

export const NO_ACTIVE_WORKFLOW_FOUND = 'No active workflow found'
export const SIGNAL_WORKFLOW_WAKE_STATE_INVALID = "ValidationException: Attempting to increment signalWakeIndex";

export const ACCESS_DENIED = "AccessDeniedException"
export const DEFAULT_LANG = REGION.startsWith('eu') ? 'en-GB' : 'en-US'

//FMUI Cookie Names
export const FMUI_LOCALE = 'fmui-locale'

//FMUI internal urls
export const ONBOARD_URL = '/onboard'
export const SINGLE_ONBOARD_URL = '/onboard/single'
export const BULK_ONBOARD_URL = '/onboard/bulk'
export const PRINCIPAL_LIST_URL = '/principal/'
export const PRINCIPAL_LIST_PATH = '/principal'
export const WORKFLOW_LIST_URL = '/workflow/'
export const MFA_REGISTRATION_URL = '/mfa-registration/'
export const MFA_REGISTRATION_PATH = '/mfa-registration'
export const AUTH_URL = '/auth'
export const PRINT_OTP_PATH = '/principal/print-otp'
export const EMPTY_PATH = ''
export const EMPTY_VALUE = ''
export const PRINCIPAL_BASELINE_PATH = '/principal/baseline/'
export const PERMISSION_BASELINE_PATH = '/permission/baseline/'

// Rendering
export const DROPDOWN_MAX_HEIGHT = '200px'

// CardItem statuses
export const STARTED = 'STARTED'
export const COMPLETED = 'COMPLETED'
export const FAILED = 'FAILED'

// Apollo Client
export const NETWORK_ONLY = 'network-only'

export const FIT_ENDPOINT = "https://beta.us-west-2.federated-auth.aft.a2z.com"  // Filled in from Webpack


export const AFTX_APP_HEADER = "User Lifecycle Management";  // No need to translate app title

// MFA Registration
export const OTP_LENGTH = 16;  // As of now, not configurable per tenant
export const MILO_REGISTRATION_SUCCESS_STATUS = "success-milo";
export const MILO_REGISTRATION_ERROR_STATUS = "failure-milo";

export const PROXY_IDP_SUCCESS_STATUS = "success-proxyidp";

// Proxy IdP Error Codes
export const PIS_ERROR_OTP_USED = "otp_used";
export const PIS_ERROR_OTP_USED_TRANSLATION_KEY = "proxy-idp-error-code-otp-used";
export const PIS_ERROR_OTP_EXPIRED = "otp_expired";
export const PIS_ERROR_OTP_EXPIRED_TRANSLATION_KEY = "proxy-idp-error-code-otp-expired";
export const PIS_ERROR_OTP_NOT_FOUND = "otp_not_found";
export const PIS_ERROR_OTP_NOT_FOUND_TRANSLATION_KEY = "proxy-idp-error-code-otp-not-found";

// query param constants
export const ERROR = "error";
export const OTP = "otp";
export const STATUS = "status";
export const STATE = "state";
export const CODE = "code";

// Regex
export const UUID_REGEX = new RegExp('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
export const NUMERIC_REGEX = new RegExp('^[0-9]');
export const URL_SECURE_ALPHANUMERIC_REGEX = new RegExp('^[a-zA-Z0-9 !?.,:@]*$');

//**************************** AFTX ****************************//

// MFA Registration
export const AFTX_MENU_ENDPOINT = "https://menu.na.integ.aftx.amazonoperations.app" // Filled in from Webpack
export const AFTX_MILO_REGISTRATION_FIT_ENDPOINT =  "https://beta.us-west-2.federated-auth.aft.a2z.com" // Filled in from Webpack
export const AFTX_MILO_REGISTRATION_FIT_TENANT = "17a102a3-f4cb-4dba-b3c2-406a8418fcf0"  // Filled in from Webpack
export const AFTX_MILO_REGISTRATION_FIT_CLIENT_ID = "fmui-milo-integ" // Filled in from Webpack
export const AFTX_MILO_REGISTRATION_ENDPOINT = "https://integ.milo.amazonoperations.com/ulmregistration-us-west-2"  // Filled in from Webpack

// AuthN
const FIT_CLIENT_DEV_AFTX_KEY = 'ulm-aftx.dev';
const FIT_CLIENT_DEV_AFTX: TokenProviderConfig = {
    tenantId: '4a09e756-286c-11ec-9621-0242ac130002',
    clientId: 'ulm-aftx-integ',
    stage: 'prod',
    region: 'us-west-2',
    callbackUrl: 'http://0.0.0.0:4321/auth',
    timeout: 2000,
};


const FIT_CLIENT_ALPHA_AFTX_KEY = 'ulm-aftx.alpha';
const FIT_CLIENT_ALPHA_AFTX: TokenProviderConfig = {
    tenantId: '4a09e756-286c-11ec-9621-0242ac130002',
    clientId: 'ulm-aftx-integ',
    stage: 'prod',
    region: 'us-west-2',
    callbackUrl: 'https://ulm.alpha.aft.amazonoperations.app/auth',
    timeout: 2000,
};

const FIT_CLIENT_BETA_AFTX_KEY = 'ulm-aftx.beta';
const FIT_CLIENT_BETA_AFTX: TokenProviderConfig = {
    tenantId: '4a09e756-286c-11ec-9621-0242ac130002',
    clientId: 'ulm-aftx-integ',
    stage: 'prod',
    region: 'us-west-2',
    callbackUrl: 'https://ulm.beta.aft.amazonoperations.app/auth',
    timeout: 2000,
};

const FIT_CLIENT_PROD_NA_AFTX_KEY = 'ulm-aftx.prod-na';
const FIT_CLIENT_PROD_NA_AFTX: TokenProviderConfig = {
    tenantId: '934e1c2c-1a34-466c-a622-e480e7bd4515',
    clientId: 'ulm-aftx-prod-na',
    stage: 'prod',
    region: 'us-east-1',
    callbackUrl: 'https://ulm.na.aft.amazonoperations.app/auth',
    timeout: 2000,
};

const FIT_CLIENT_PROD_EU_AFTX_KEY = 'ulm-aftx.prod-eu';
const FIT_CLIENT_PROD_EU_AFTX: TokenProviderConfig = {
    tenantId: '934e1c2c-1a34-466c-a622-e480e7bd4515',
    clientId: 'ulm-aftx-prod-eu',
    stage: 'prod',
    region: 'eu-west-1',
    callbackUrl: 'https://ulm.eu.aft.amazonoperations.app/auth',
    timeout: 2000,
};

const FIT_CLIENT_PROD_FE_AFTX_KEY = 'ulm-aftx.prod-fe';
const FIT_CLIENT_PROD_FE_AFTX: TokenProviderConfig = {
    tenantId: '934e1c2c-1a34-466c-a622-e480e7bd4515',
    clientId: 'ulm-aftx-prod-fe',
    stage: 'prod',
    region: 'us-west-2',
    callbackUrl: 'https://ulm.fe.aft.amazonoperations.app/auth',
    timeout: 2000,
};

export const FIT_CLIENT_CONFIGS = new Map([
    [FIT_CLIENT_DEV_AFTX_KEY, FIT_CLIENT_DEV_AFTX],
    [FIT_CLIENT_ALPHA_AFTX_KEY, FIT_CLIENT_ALPHA_AFTX],
    [FIT_CLIENT_BETA_AFTX_KEY, FIT_CLIENT_BETA_AFTX],
    [FIT_CLIENT_PROD_NA_AFTX_KEY, FIT_CLIENT_PROD_NA_AFTX],
    [FIT_CLIENT_PROD_EU_AFTX_KEY, FIT_CLIENT_PROD_EU_AFTX],
    [FIT_CLIENT_PROD_FE_AFTX_KEY, FIT_CLIENT_PROD_FE_AFTX],
]);

export const FIT_CLIENT_CONFIG_KEY = "ulm-aftx.alpha"  // Filled in from webpack
export const FIT_CLIENT_CONFIG_DEFAULT: TokenProviderConfig = FIT_CLIENT_BETA_AFTX;

export const SESSION_STORAGE_REDIRECT_BACK_PATH = 'redirect_back_path';
export const SESSION_STORAGE_REDIRECT_BACK_SEARCH = 'redirect_back_search';

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export const MIME_TYPE_XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export const DATE_FORMAT = "YYYY-MM-DD";
export const EXCEL_DATE_FORMAT = 'yyyy-mm-dd';

// Bulk User Onboard Template
export const DATA_SHEET_NAME = 'Data';

export const MAX_ONBOARD_USER_COUNT = 100;
export const COLUMN_NAME_EXCEL_ROW = 3;
export const DATA_START_EXCEL_ROW = COLUMN_NAME_EXCEL_ROW + 1;
export const DATA_END_EXCEL_ROW = DATA_START_EXCEL_ROW + MAX_ONBOARD_USER_COUNT;
// TODO: When enabling bulk onboard versioning update this value.
//  Currently, it does nothing.
export const BULK_ONBOARD_IMPORT_VERSION = "V1";
export const DO_NOT_DELETE_EXCEL_SHEET_PROTECTION_STRING = "notSecureOnPurpose";
export const FILLED_IN_VIA_A_REFERENCE_AT_RUNTIME = [''];
export const BLACK_COLOR_HEXADECIMAL = "000";

/**
 * Used to validate a person has to be older than the value below to be onboarded.
 * Requiring someone to older than 10 years was arbitrarily selected.
 */
export const DOB_CUTOFF_IN_YEARS = 10;

export const MILLISECONDS_IN_A_SECOND = 1000;
// Years * Days * Hours * Minutes * Seconds * Milliseconds
export const TEN_YEARS_IN_MILLISECONDS = DOB_CUTOFF_IN_YEARS * 365 * 24 * 60 * 60 * MILLISECONDS_IN_A_SECOND;

export const ONBOARDABLE_USER_TYPES: string[] = [
    UserType.ASSOCIATE,
    UserType.USER_ADMIN_PRIMARY,
    UserType.USER_ADMIN_SECONDARY,
]

export const AFTX_BASE_ROLES: string[] = [
    AftxRole.BASE_PROCESS_ASSOCIATE,
    AftxRole.IB_OB_CLERK,
    AftxRole.IB_OB_TEAM_LEADER,
    AftxRole.ICQA_PROBLEM_SOLVE,
]

export const GENDER_OPTIONS = [
    {label: "gender-male", value: GenderType.MALE},
    {label: "gender-female", value: GenderType.FEMALE},
    {label: "gender-not-disclosed", value: GenderType.NOT_DISCLOSED},
    {label: "gender-unknown", value: GenderType.UNKNOWN},
]

export const NATIONAL_ID_COUNTRY_OPTIONS = [
    {label: 'Aruba', value: NationalIdCountry.ABW},
    {label: 'Afghanistan', value: NationalIdCountry.AFG},
    {label: 'Angola', value: NationalIdCountry.AGO},
    {label: 'Anguilla', value: NationalIdCountry.AIA},
    {label: 'Åland Islands', value: NationalIdCountry.ALA},
    {label: 'Albania', value: NationalIdCountry.ALB},
    {label: 'Andorra', value: NationalIdCountry.AND},
    {label: 'United Arab Emirates', value: NationalIdCountry.ARE},
    {label: 'Argentina', value: NationalIdCountry.ARG},
    {label: 'Armenia', value: NationalIdCountry.ARM},
    {label: 'American Samoa', value: NationalIdCountry.ASM},
    {label: 'Antigua and Barbuda', value: NationalIdCountry.ATG},
    {label: 'Australia', value: NationalIdCountry.AUS},
    {label: 'Austria', value: NationalIdCountry.AUT},
    {label: 'Azerbaijan', value: NationalIdCountry.AZE},
    {label: 'Burundi', value: NationalIdCountry.BDI},
    {label: 'Belgium', value: NationalIdCountry.BEL},
    {label: 'Benin', value: NationalIdCountry.BEN},
    {label: 'Bonaire, Sint Eustatius and Saba', value: NationalIdCountry.BES},
    {label: 'Burkina Faso', value: NationalIdCountry.BFA},
    {label: 'Bangladesh', value: NationalIdCountry.BGD},
    {label: 'Bulgaria', value: NationalIdCountry.BGR},
    {label: 'Bahrain', value: NationalIdCountry.BHR},
    {label: 'Bahamas', value: NationalIdCountry.BHS},
    {label: 'Bosnia and Herzegovina', value: NationalIdCountry.BIH},
    {label: 'Saint Barthélemy', value: NationalIdCountry.BLM},
    {label: 'Belarus', value: NationalIdCountry.BLR},
    {label: 'Belize', value: NationalIdCountry.BLZ},
    {label: 'Bermuda', value: NationalIdCountry.BMU},
    {label: 'Bolivia', value: NationalIdCountry.BOL},
    {label: 'Brazil', value: NationalIdCountry.BRA},
    {label: 'Barbados', value: NationalIdCountry.BRB},
    {label: 'Brunei Darussalam', value: NationalIdCountry.BRN},
    {label: 'Bhutan', value: NationalIdCountry.BTN},
    {label: 'Botswana', value: NationalIdCountry.BWA},
    {label: 'Central African Republic', value: NationalIdCountry.CAF},
    {label: 'Canada', value: NationalIdCountry.CAN},
    {label: 'Cocos', value: NationalIdCountry.CCK},
    {label: 'Switzerland', value: NationalIdCountry.CHE},
    {label: 'Chile', value: NationalIdCountry.CHL},
    {label: "Côte d'Ivoire", value: NationalIdCountry.CIV},
    {label: 'Cameroon', value: NationalIdCountry.CMR},
    {label: 'Congo', value: NationalIdCountry.COD},
    {label: 'Congo', value: NationalIdCountry.COG},
    {label: 'Cook Islands', value: NationalIdCountry.COK},
    {label: 'Comoros', value: NationalIdCountry.COM},
    {label: 'Cabo Verde', value: NationalIdCountry.CPV},
    {label: 'Cuba', value: NationalIdCountry.CUB},
    {label: 'Curaçao', value: NationalIdCountry.CUW},
    {label: 'Christmas Island', value: NationalIdCountry.CXR},
    {label: 'Cayman Islands', value: NationalIdCountry.CYM},
    {label: 'Cyprus', value: NationalIdCountry.CYP},
    {label: 'Czechia', value: NationalIdCountry.CZE},
    {label: 'Germany', value: NationalIdCountry.DEU},
    {label: 'Djibouti', value: NationalIdCountry.DJI},
    {label: 'Dominica', value: NationalIdCountry.DMA},
    {label: 'Denmark', value: NationalIdCountry.DNK},
    {label: 'Dominican Republic', value: NationalIdCountry.DOM},
    {label: 'Algeria', value: NationalIdCountry.DZA},
    {label: 'Ecuador', value: NationalIdCountry.ECU},
    {label: 'Egypt', value: NationalIdCountry.EGY},
    {label: 'Eritrea', value: NationalIdCountry.ERI},
    {label: 'Western Sahara', value: NationalIdCountry.ESH},
    {label: 'Spain', value: NationalIdCountry.ESP},
    {label: 'Estonia', value: NationalIdCountry.EST},
    {label: 'Ethiopia', value: NationalIdCountry.ETH},
    {label: 'Finland', value: NationalIdCountry.FIN},
    {label: 'Fiji', value: NationalIdCountry.FJI},
    {label: 'Falkland Islands', value: NationalIdCountry.FLK},
    {label: 'France', value: NationalIdCountry.FRA},
    {label: 'Faroe Islands', value: NationalIdCountry.FRO},
    {label: 'Micronesia', value: NationalIdCountry.FSM},
    {label: 'Gabon', value: NationalIdCountry.GAB},
    {label: 'United Kingdom', value: NationalIdCountry.GBR},
    {label: 'Georgia', value: NationalIdCountry.GEO},
    {label: 'Guernsey', value: NationalIdCountry.GGY},
    {label: 'Ghana', value: NationalIdCountry.GHA},
    {label: 'Gibraltar', value: NationalIdCountry.GIB},
    {label: 'Guinea', value: NationalIdCountry.GIN},
    {label: 'Guadeloupe', value: NationalIdCountry.GLP},
    {label: 'Gambia', value: NationalIdCountry.GMB},
    {label: 'Guinea-Bissau', value: NationalIdCountry.GNB},
    {label: 'Equatorial Guinea', value: NationalIdCountry.GNQ},
    {label: 'Greece', value: NationalIdCountry.GRC},
    {label: 'Grenada', value: NationalIdCountry.GRD},
    {label: 'Greenland', value: NationalIdCountry.GRL},
    {label: 'Guatemala', value: NationalIdCountry.GTM},
    {label: 'French Guiana', value: NationalIdCountry.GUF},
    {label: 'Guam', value: NationalIdCountry.GUM},
    {label: 'Guyana', value: NationalIdCountry.GUY},
    {label: 'Honduras', value: NationalIdCountry.HND},
    {label: 'Croatia', value: NationalIdCountry.HRV},
    {label: 'Haiti', value: NationalIdCountry.HTI},
    {label: 'Hungary', value: NationalIdCountry.HUN},
    {label: 'Indonesia', value: NationalIdCountry.IDN},
    {label: 'Isle of Man', value: NationalIdCountry.IMN},
    {label: 'India', value: NationalIdCountry.IND},
    {label: 'British Indian Ocean Territory', value: NationalIdCountry.IOT},
    {label: 'Ireland', value: NationalIdCountry.IRL},
    {label: 'Iran', value: NationalIdCountry.IRN},
    {label: 'Iraq', value: NationalIdCountry.IRQ},
    {label: 'Iceland', value: NationalIdCountry.ISL},
    {label: 'Israel', value: NationalIdCountry.ISR},
    {label: 'Italy', value: NationalIdCountry.ITA},
    {label: 'Jamaica', value: NationalIdCountry.JAM},
    {label: 'Jersey', value: NationalIdCountry.JEY},
    {label: 'Jordan', value: NationalIdCountry.JOR},
    {label: 'Kazakhstan', value: NationalIdCountry.KAZ},
    {label: 'Kenya', value: NationalIdCountry.KEN},
    {label: 'Kyrgyzstan', value: NationalIdCountry.KGZ},
    {label: 'Cambodia', value: NationalIdCountry.KHM},
    {label: 'Kiribati', value: NationalIdCountry.KIR},
    {label: 'Saint Kitts and Nevis', value: NationalIdCountry.KNA},
    {label: 'Korea', value: NationalIdCountry.KOR},
    {label: 'Kuwait', value: NationalIdCountry.KWT},
    {label: "Lao People's Democratic Republic", value: NationalIdCountry.LAO},
    {label: 'Lebanon', value: NationalIdCountry.LBN},
    {label: 'Liberia', value: NationalIdCountry.LBR},
    {label: 'Libya', value: NationalIdCountry.LBY},
    {label: 'Saint Lucia', value: NationalIdCountry.LCA},
    {label: 'Liechtenstein', value: NationalIdCountry.LIE},
    {label: 'Sri Lanka', value: NationalIdCountry.LKA},
    {label: 'Lesotho', value: NationalIdCountry.LSO},
    {label: 'Lithuania', value: NationalIdCountry.LTU},
    {label: 'Luxembourg', value: NationalIdCountry.LUX},
    {label: 'Latvia', value: NationalIdCountry.LVA},
    {label: 'Macao', value: NationalIdCountry.MAC},
    {label: 'Saint Martin', value: NationalIdCountry.MAF},
    {label: 'Morocco', value: NationalIdCountry.MAR},
    {label: 'Monaco', value: NationalIdCountry.MCO},
    {label: 'Moldova', value: NationalIdCountry.MDA},
    {label: 'Madagascar', value: NationalIdCountry.MDG},
    {label: 'Maldives', value: NationalIdCountry.MDV},
    {label: 'Mexico', value: NationalIdCountry.MEX},
    {label: 'Marshall Islands', value: NationalIdCountry.MHL},
    {label: 'North Macedonia', value: NationalIdCountry.MKD},
    {label: 'Mali', value: NationalIdCountry.MLI},
    {label: 'Malta', value: NationalIdCountry.MLT},
    {label: 'Myanmar', value: NationalIdCountry.MMR},
    {label: 'Montenegro', value: NationalIdCountry.MNE},
    {label: 'Mongolia', value: NationalIdCountry.MNG},
    {label: 'Northern Mariana Islands', value: NationalIdCountry.MNP},
    {label: 'Mozambique', value: NationalIdCountry.MOZ},
    {label: 'Mauritania', value: NationalIdCountry.MRT},
    {label: 'Montserrat', value: NationalIdCountry.MSR},
    {label: 'Martinique', value: NationalIdCountry.MTQ},
    {label: 'Mauritius', value: NationalIdCountry.MUS},
    {label: 'Malawi', value: NationalIdCountry.MWI},
    {label: 'Malaysia', value: NationalIdCountry.MYS},
    {label: 'Mayotte', value: NationalIdCountry.MYT},
    {label: 'Namibia', value: NationalIdCountry.NAM},
    {label: 'New Caledonia', value: NationalIdCountry.NCL},
    {label: 'Niger', value: NationalIdCountry.NER},
    {label: 'Norfolk Island', value: NationalIdCountry.NFK},
    {label: 'Nigeria', value: NationalIdCountry.NGA},
    {label: 'Nicaragua', value: NationalIdCountry.NIC},
    {label: 'Niue', value: NationalIdCountry.NIU},
    {label: 'Netherlands', value: NationalIdCountry.NLD},
    {label: 'Norway', value: NationalIdCountry.NOR},
    {label: 'Nepal', value: NationalIdCountry.NPL},
    {label: 'Nauru', value: NationalIdCountry.NRU},
    {label: 'New Zealand', value: NationalIdCountry.NZL},
    {label: 'Oman', value: NationalIdCountry.OMN},
    {label: 'Pakistan', value: NationalIdCountry.PAK},
    {label: 'Panama', value: NationalIdCountry.PAN},
    {label: 'Pitcairn', value: NationalIdCountry.PCN},
    {label: 'Peru', value: NationalIdCountry.PER},
    {label: 'Philippines', value: NationalIdCountry.PHL},
    {label: 'Palau', value: NationalIdCountry.PLW},
    {label: 'Papua New Guinea', value: NationalIdCountry.PNG},
    {label: 'Poland', value: NationalIdCountry.POL},
    {label: 'Puerto Rico', value: NationalIdCountry.PRI},
    {label: 'Korea', value: NationalIdCountry.PRK},
    {label: 'Portugal', value: NationalIdCountry.PRT},
    {label: 'Paraguay', value: NationalIdCountry.PRY},
    {label: 'Palestine, State of', value: NationalIdCountry.PSE},
    {label: 'French Polynesia', value: NationalIdCountry.PYF},
    {label: 'Qatar', value: NationalIdCountry.QAT},
    {label: 'Réunion', value: NationalIdCountry.REU},
    {label: 'Romania', value: NationalIdCountry.ROU},
    {label: 'Russian Federation', value: NationalIdCountry.RUS},
    {label: 'Rwanda', value: NationalIdCountry.RWA},
    {label: 'Saudi Arabia', value: NationalIdCountry.SAU},
    {label: 'Sudan', value: NationalIdCountry.SDN},
    {label: 'Senegal', value: NationalIdCountry.SEN},
    {label: 'Singapore', value: NationalIdCountry.SGP},
    {label: 'Saint Helena, Ascension and Tristan da Cunha', value: NationalIdCountry.SHN},
    {label: 'Svalbard and Jan Mayen', value: NationalIdCountry.SJM},
    {label: 'Solomon Islands', value: NationalIdCountry.SLB},
    {label: 'Sierra Leone', value: NationalIdCountry.SLE},
    {label: 'El Salvador', value: NationalIdCountry.SLV},
    {label: 'San Marino', value: NationalIdCountry.SMR},
    {label: 'Somalia', value: NationalIdCountry.SOM},
    {label: 'Saint Pierre and Miquelon', value: NationalIdCountry.SPM},
    {label: 'Serbia', value: NationalIdCountry.SRB},
    {label: 'South Sudan', value: NationalIdCountry.SSD},
    {label: 'Sao Tome and Principe', value: NationalIdCountry.STP},
    {label: 'Suriname', value: NationalIdCountry.SUR},
    {label: 'Slovakia', value: NationalIdCountry.SVK},
    {label: 'Slovenia', value: NationalIdCountry.SVN},
    {label: 'Sweden', value: NationalIdCountry.SWE},
    {label: 'Eswatini', value: NationalIdCountry.SWZ},
    {label: 'Sint Maarten', value: NationalIdCountry.SXM},
    {label: 'Seychelles', value: NationalIdCountry.SYC},
    {label: 'Syrian Arab Republic', value: NationalIdCountry.SYR},
    {label: 'Turks and Caicos Islands', value: NationalIdCountry.TCA},
    {label: 'Chad', value: NationalIdCountry.TCD},
    {label: 'Togo', value: NationalIdCountry.TGO},
    {label: 'Thailand', value: NationalIdCountry.THA},
    {label: 'Tajikistan', value: NationalIdCountry.TJK},
    {label: 'Tokelau', value: NationalIdCountry.TKL},
    {label: 'Turkmenistan', value: NationalIdCountry.TKM},
    {label: 'Timor-Leste', value: NationalIdCountry.TLS},
    {label: 'Tonga', value: NationalIdCountry.TON},
    {label: 'Trinidad and Tobago', value: NationalIdCountry.TTO},
    {label: 'Tunisia', value: NationalIdCountry.TUN},
    {label: 'Türkiye', value: NationalIdCountry.TUR},
    {label: 'Tuvalu', value: NationalIdCountry.TUV},
    {label: 'Tanzania, the United Republic of', value: NationalIdCountry.TZA},
    {label: 'Uganda', value: NationalIdCountry.UGA},
    {label: 'Ukraine', value: NationalIdCountry.UKR},
    {label: 'United States Minor Outlying Islands', value: NationalIdCountry.UMI},
    {label: 'Uruguay', value: NationalIdCountry.URY},
    {label: 'United States of America', value: NationalIdCountry.USA},
    {label: 'Uzbekistan', value: NationalIdCountry.UZB},
    {label: 'Holy See', value: NationalIdCountry.VAT},
    {label: 'Saint Vincent and the Grenadines', value: NationalIdCountry.VCT},
    {label: 'Venezuela', value: NationalIdCountry.VEN},
    {label: 'Virgin Islands (British)', value: NationalIdCountry.VGB},
    {label: 'Virgin Islands (U.S.)', value: NationalIdCountry.VIR},
    {label: 'Viet Nam', value: NationalIdCountry.VNM},
    {label: 'Vanuatu', value: NationalIdCountry.VUT},
    {label: 'Wallis and Futuna', value: NationalIdCountry.WLF},
    {label: 'Samoa', value: NationalIdCountry.WSM},
    {label: 'Kosovo', value: NationalIdCountry.XKX},
    {label: 'Yemen', value: NationalIdCountry.YEM},
    {label: 'South Africa', value: NationalIdCountry.ZAF},
    {label: 'Zambia', value: NationalIdCountry.ZMB},
    {label: 'Zimbabwe', value: NationalIdCountry.ZWE},
]

export const NATIONAL_ID_TYPE_OPTIONS = [
    {label: NationalIdType.NATIONAL_ID_TAIL, value: NationalIdType.NATIONAL_ID_TAIL},
]

/**
 * The time between each poll for workflow state
 */
export const POLL_WORKFLOW_STATE_INTERVAL = 5 * MILLISECONDS_IN_A_SECOND;

//**************************** SOX ****************************//
export const SOX_APP_HEADER = "ULM: AFT Tool Permissions Access Review"; // No need to translate app title
export const FIT_SOX_IDP_LOGIN_HINT = "amazon-federate-integ"  // Filled in from webpack
export const S3_PULLED_DATA = "s3PulledData";
export const PERMISSION_BASELINING_DATA = "permissionBaseliningData";

export const ABBREVIATION_TO_PERMISSION_MAP : Map<string, BaseliningPermissionOperation> =
    new Map<string, BaseliningPermissionOperation>([
        ['N', BaseliningPermissionOperation.NONE],
        ['B', BaseliningPermissionOperation.BEGINNER],
        ['I', BaseliningPermissionOperation.INTERMEDIATE],
        ['E', BaseliningPermissionOperation.EXPERT],
        ['A', BaseliningPermissionOperation.ADMIN]]);

export const PERMISSION_TO_ABBREVIATION_MAP : Map<BaseliningPermissionOperation, string> =
    new Map<BaseliningPermissionOperation, string>([
        [BaseliningPermissionOperation.NONE, 'N'],
        [BaseliningPermissionOperation.BEGINNER, 'B'],
        [BaseliningPermissionOperation.INTERMEDIATE, 'I'],
        [BaseliningPermissionOperation.EXPERT, 'E'],
        [BaseliningPermissionOperation.ADMIN, 'A']]);

//**************************** SCC ****************************//

export const SCC_MILO_REGISTRATION_FIT_ENDPOINT =  "https://beta.us-west-2.federated-auth.aft.a2z.com" // Filled in from Webpack
export const SCC_MILO_REGISTRATION_FIT_TENANT = "ec03b1a3-6e86-4586-b861-059c91cfbb7f"  // Filled in from Webpack
export const SCC_MILO_REGISTRATION_FIT_CLIENT_ID = "milo-scc-integ" // Filled in from Webpack
export const SCC_MILO_REGISTRATION_ENDPOINT = "https://integ.milo.amazonoperations.com/sccregistration-us-west-2"  // Filled in from Webpack
