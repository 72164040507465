import React, { useEffect, useState } from 'react'
import { Button } from '@amzn/alchemy-components-react'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { BreadCrumbs } from 'src/components/breadcrumb'
import { SiteFilter } from 'src/components/site-filter'
import { ENTERPRISE_SCOPE, NETWORK_ONLY } from 'src/common/constants'
import { RenderPrincipalCards } from './render-principal-cards'
import { BaseliningState, PageType, TenantID, UserType } from "src/common/enums";
import { Principal } from "src/models/principal";
import { PageProps } from "../page-interface";
import { logger } from "src/logger";
import { RegisterMfaModal } from "src/components/modals/register-mfa-modal";
import { LIST_PRINCIPALS_QUERY } from "src/common/gql-operations";
import { RegisterMfaModalHooks } from "src/models/register-mfa-modal-hooks";
import { RemoveScopeModalHooks } from "src/models/remove-principal-scope-modal-hooks";
import { RemoveScopeModal } from "src/components/modals/remove-principal-scope-modal";
import { PrincipalCard } from "src/models/principal-card";
import { useAuth } from "src/components/auth/auth-provider";
import * as KatalMetrics from "@amzn/katal-metrics";
import initialMetricsPublisher from "src/metrics";
import { convertDateTimeToLocaleFormat } from "src/common/util";
import { convertUnixTimeStampToDate, downloadExcelFile, loadExcelFile } from "src/common/excel-util";
import {TokenUtil} from "src/common/token-util";
import { BulkRegisterMfaModal } from "src/components/modals/bulk-register-mfa-modal";
import { BulkRegisterMfaModalHooks } from "src/models/bulk-register-mfa-modal-hooks";
import { BulkAction } from "src/components/bulk-action";

const cloudWatchDimensions = [
    new KatalMetrics.Metric.String('page', 'principal-list'),
]

// @ts-ignore
const additionalMetricsContext = new KatalMetrics.Context({cloudWatchDimensions});
const graphqlClientMetricsPublisher =
    initialMetricsPublisher.newChildActionPublisherForMethod('graphql-client', additionalMetricsContext);

/**
 * Component to create PrincipalList Page.
 */
export const PrincipalList = (props: PageProps) => {

    const auth = useAuth();
    const { t } = useTranslation()
    const locale = document.documentElement.lang;   // e.g. en-US

    // TODO: Check with UX on final mockups to see if we should provide baselining / userType filters here

    // TODO: listPrincipals allow 'lastBaselinedBefore' date in input check if we want to provide date filter

    const [getPrincipalScopesLoading, setGetPrincipalScopesLoading] = useState<boolean>(true);
    const [site, setSite] = useState<string>()
    const [scopeOptions, setScopeOptions] = useState<any>([]);
    const [principalCards, setPrincipalCards] = useState<Map<string, PrincipalCard>>(new Map());
    const [principals, setPrincipals] = useState<Principal[]>([]);
    const [downloadButtonAvailable, setDownloadButtonAvailable] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>()

    const [registerMfaModalOpen, setRegisterMfaModalOpen] = useState<boolean>(false);
    const [registerMfaModalPrincipalArn, setRegisterMfaModalPrincipalArn] = useState<string>();
    const [registerMfaModalFullName, setRegisterMfaModalFullName] = useState<string>();

    const [removeScopeModalOpen, setRemoveScopeModalOpen] = useState<boolean>(false);
    const [removeScopeModalPrincipalArn, setRemoveScopeModalPrincipalArn] = useState<string>();
    const [removeScopeModalFullName, setRemoveScopeModalFullName] = useState<string>();
    const [removeScopeModalTenantId, setRemoveScopeModalTenantId] = useState<string>();
    const [removeScopeModalScope, setRemoveScopeModalScope] = useState<string>();
    const [removeScopeModalUserType, setRemoveScopeModalUserType] = useState<UserType>();

    const [bulkRegisterMfaModalOpen, setBulkRegisterMfaModalOpen] = useState<boolean>(false);
    const [selectedPrincipalCards, setSelectedPrincipalCards] = useState<Map<string, PrincipalCard>>(new Map());

    const registerMfaModalHooks: RegisterMfaModalHooks = {
        setOpen: setRegisterMfaModalOpen,
        setPrincipalArn: setRegisterMfaModalPrincipalArn,
        setFullName: setRegisterMfaModalFullName
    }

    const removeScopeModalHooks: RemoveScopeModalHooks = {
        setOpen: setRemoveScopeModalOpen,
        setPrincipalArn: setRemoveScopeModalPrincipalArn,
        setFullName: setRemoveScopeModalFullName,
        setTenantId: setRemoveScopeModalTenantId,
        setScope: setRemoveScopeModalScope,
        setUserType: setRemoveScopeModalUserType
    }

    const bulkRegisterMfaModalHooks: BulkRegisterMfaModalHooks = {
        setOpen: setBulkRegisterMfaModalOpen,
    }

    const [listPrincipals, { loading: listPrincipalsLoading, error, data }] = useLazyQuery(LIST_PRINCIPALS_QUERY, {
        fetchPolicy: NETWORK_ONLY,
    })

    useEffect(() => {
        props.setActivePage(PageType.PRINCIPAL_LIST);
        populateScopes();
    }, []);

    useEffect(() => {
        if (site) {
            callListPrincipals(site);
        }

    }, [site]);

    useEffect(() => {
        if (!listPrincipalsLoading && data) {
            const principalCards = new Map<string, PrincipalCard>();
            const fetchedPrincipals: Principal[] = data.listPrincipals.principals;
            logger.info(`Fetched Principals: ${JSON.stringify(fetchedPrincipals)}`);
            const tenantId = data.listPrincipals.tenantId;

            for (const principal of fetchedPrincipals) {
                principalCards.set(principal.principalArn, {
                    items: getCardItems(tenantId, principal),
                    expanded: false,
                    fullName: `${principal.firstName} ${principal.lastName}`,
                    scope: principal.scope,
                    userType: principal.userType,
                    selected: false
                })
            }

            logger.info(`Users fetched successfully for siteId: ${site}, userTypes:${Object.values(UserType)},
                        baselineStates:${Object.values(BaseliningState)}`)
            setPrincipalCards(principalCards);

            setPrincipals(fetchedPrincipals);
            setErrorMessage(undefined);
            graphqlClientMetricsPublisher.publishCounterMonitor('list-principals.SUCCESS', 1);
        }
    }, [data, t])

    useEffect(() => {
        if (error) {
            setPrincipalCards(new Map());
            let message = `${t('list-principals-error-fetching-users')}: ${site}, userTypes:${Object.values(UserType)},
                             baselineStates:${Object.values(BaseliningState)}. ` + error
            setErrorMessage(message)
            logger.info(message, error)
            graphqlClientMetricsPublisher.publishCounterMonitor('list-principals.ERROR', 1);
        }
    }, [error])

    useEffect(() => {
        allowedToDownloadPrincipals();
    }, [principals, scopeOptions]);

    useEffect(() => {
        // Principal Cards are a map of PrincipalArn keys
        setSelectedPrincipalCards(getSelectedCards(principalCards));
    }, [principalCards]);

    const callListPrincipals = (site: string) => {
        const listPrincipalsInput = {
            tenantId: TenantID.AFTX,
            scope: site,
            baselineStates: Object.values(BaseliningState),
            userTypes: Object.values(UserType)
        };
        logger.info('Calling listPrincipals.', listPrincipalsInput);
        graphqlClientMetricsPublisher.publishCounterMonitor('list-principals.INVOCATION', 1);
        listPrincipals({
            variables: {
                listPrincipalsInput: listPrincipalsInput
            }
        });
    }

    /**
     * Populates the filter with the scopes to which the current user has access.
     */
    const populateScopes = async() => {
        const scopes = await auth.getScopes();

        if (scopes && scopes.length > 0) {
            scopes.forEach(scope => scopeOptions.push({
                label: scope,
                value: scope
            }));
        } else {
            setErrorMessage(t('failed-to-get-sites-user-can-access'));
        }

        setScopeOptions(scopeOptions);
        setSite(scopes[0]); // default to first scope to which user has access
        setGetPrincipalScopesLoading(false);
    }

    /**
     * Gets the list of {@link CardItem} from the {@link Principal} object.
     *
     * @param tenantId The id of the tenant.
     * @param principal The principal from which to get the data for the card items.
     */
    const getCardItems = (tenantId: string, principal: Principal) => {
        return [
            {
                attributeName: t('user-arn'),
                attributeValue: principal.principalArn,
            },
            {
                attributeName: t('tenant-id'),
                attributeValue: tenantId,
            },
            {
                attributeName: t('user-type'),
                attributeValue: principal.userType,
            },
            {
                attributeName: t('first-name'),
                attributeValue: principal.firstName
            },
            {
                attributeName: t('last-name'),
                attributeValue: principal.lastName
            },
            {
                attributeName: t('site'),
                attributeValue: principal.scope,
            },
            {
                attributeName: t('last-baselining-date'),
                attributeValue: principal.lastBaseliningDate ?
                    convertDateTimeToLocaleFormat(locale, new Date(principal.lastBaseliningDate)) : ''
            },
            {
                attributeName: t('last-baselined-by-principal'),
                attributeValue: principal.lastBaselinedByPrincipal,
            }
        ]
    }

    const getSelectedCards = (principalCards: Map<string, PrincipalCard>) => {
        const selectedCards = new Map<string, PrincipalCard>();
        for (const principalArn of principalCards.keys()) {
            const principalCard = principalCards.get(principalArn)!;
            if (principalCard.selected) {
                selectedCards.set(principalArn, principalCard);
            }
        }
        return selectedCards;
    }

    /**
     * Expands all principal cards.
     *
     * @param principalCards The principal cards to expand.
     */
    const expandAllCards = (principalCards: Map<string, PrincipalCard>) => {
        for (const principalArn of principalCards.keys()) {
            const principalCard = principalCards.get(principalArn)!;
            principalCards.set(principalArn, {
                items: principalCard.items,
                expanded: true,
                fullName: principalCard.fullName,
                scope: principalCard.scope,
                userType: principalCard.userType,
                selected: principalCard.selected
            });
        }

        setPrincipalCards(new Map(principalCards));
    }

    /**
     * Collapses all principal cards.
     *
     * @param principalCards The principal cards to collapse.
     */
    const collapseAllCards = (principalCards: Map<string, PrincipalCard>) => {
        for (const principalArn of principalCards.keys()) {
            const principalCard = principalCards.get(principalArn)!;
            principalCards.set(principalArn, {
                items: principalCard.items,
                expanded: false,
                fullName: principalCard.fullName,
                scope: principalCard.scope,
                userType: principalCard.userType,
                selected: principalCard.selected
            });
        }

        setPrincipalCards(new Map(principalCards));
    }

    /**
     * Expands the principal card if it's collapsed or collapses the principal card if it's expanded.
     *
     * @param principalArn The principal arn of the principal card to expand or collapse.
     */
    const toggleExpand = (principalArn: string) => {
        const principalCard = principalCards.get(principalArn)!;
        principalCards.set(principalArn, {
            items: principalCard.items,
            expanded: !principalCard.expanded,
            fullName: principalCard.fullName,
            scope: principalCard.scope,
            userType: principalCard.userType,
            selected: principalCard.selected
        });

        setPrincipalCards(new Map(principalCards));
    }

    /**
     * Selects the principal card if it's unselected or deselects the principal card if it's selected.
     *
     * @param principalArn The principal arn of the principal card to select or deselect.
     */
    const toggleSelect = (principalArn: string) => {
        const principalCard = principalCards.get(principalArn)!;
        principalCards.set(principalArn, {
            items: principalCard.items,
            expanded: principalCard.expanded,
            fullName: principalCard.fullName,
            scope: principalCard.scope,
            userType: principalCard.userType,
            selected: !principalCard.selected
        });

        setPrincipalCards(new Map(principalCards));
    }


    const allowedToDownloadPrincipals = () => {
        // No principals
        if (principals === undefined || principals.length === 0) {
            logger.info("No principals not showing the download button")
            setDownloadButtonAvailable(false);
            return;
        }

        // Check for system admin
        const enterpriseScope = scopeOptions.find((s: any) => s.value === ENTERPRISE_SCOPE);
        if (enterpriseScope) {
            logger.info("Show download button for System Admins");
            setDownloadButtonAvailable(true);
            return;
        }

        // Determine if the user is an ABBM at the location
        const currentUser = principals.find(principal => principal.principalArn === TokenUtil.getPrincipalArn());
        if (
            currentUser !== undefined
                && [UserType.ABBM_PRIMARY.toString(), UserType.ABBM_SECONDARY.toString()].includes(currentUser.userType)
        ) {
            logger.info("User is an ABBM. Allowed to Download users.");
            setDownloadButtonAvailable(true);
        } else {
            logger.info("User is NOT an ABBM. Not allowed to Download users.");
            setDownloadButtonAvailable(false);
        }
    }

    const downloadPrincipals = async () => {
        const workbook = await loadExcelFile('users-list.xlsx');

        // Header
        const dataSheet = workbook.worksheets[0];
        let row = dataSheet.getRow(1);
        row.font = {bold: true};
        row.border = {bottom: {style: 'thin'}};
        row.getCell(1).value = t('warehouse') as string;
        row.getCell(2).value = t('first-name') as string;
        row.getCell(3).value = t('last-name') as string;
        row.getCell(4).value = t('creation-date') as string;
        row.getCell(5).value = t('hire-start-date') as string;
        row.getCell(6).value = t('alias') as string;

        // Data
        principals.forEach((principal, index) => {
            logger.debug(`principal = ${JSON.stringify(principal)}`);
            row = dataSheet.getRow(index + 2);
            row.getCell(1).value = site;
            row.getCell(2).value = principal.firstName;
            row.getCell(3).value = principal.lastName;
            row.getCell(4).value = convertUnixTimeStampToDate(principal.creationDate);  // Excel handles the date formatting
            row.getCell(5).value = convertUnixTimeStampToDate(principal.startDate);  // Excel handles the date formatting
            row.getCell(6).value = principal.alias;
        });

        const today = new Date().toISOString().substring(0, 10);
        await downloadExcelFile(workbook, `${site}-users-list-${today}.xlsx`)
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <BreadCrumbs breadcrumbItems={[{ tag: t('users'), path: '/principal' }]} />
            </div>
            <div className="row b-background mx-0">
                <div className="col align-self-center title m-0 py-1">{t('users')}</div>
            </div>
            <div className="row b-background mx-0 align-items-center">
                <div className="col-lg p-1">
                    {(scopeOptions.length > 0) &&
                        <SiteFilter
                            sites={scopeOptions}
                            site={site}
                            setSite={setSite}
                            status={site ? '' : t('required')}
                        />
                    }
                </div>
                <div className="col-lg-auto d-flex p-1">
                    {/* TODO: Optimize and add more functionality to the menu buttons:
                      *  https://sim.amazon.com/issues/AFTI-1461
                      **/}

                    {/* Large screen size icons */}
                    <Button
                        className="mr-1 dashboard-buttons d-none d-xl-block"
                        icon="showDetail"
                        iconPosition="right"
                        label={t('expand-all')}
                        onClick={() => expandAllCards(principalCards)}
                        id="expand-all"
                    />
                    <Button
                        className="dashboard-buttons mr-1 d-none d-xl-block"
                        icon="hideDetail"
                        iconPosition="right"
                        label={t('collapse-all')}
                        onClick={() => collapseAllCards(principalCards)}
                        id="collapse-all"
                    />
                    {downloadButtonAvailable && <Button
                        className="dashboard-buttons mr-1 d-none d-xl-block"
                        icon="download"
                        iconPosition="right"
                        label={t('download')}
                        onClick={downloadPrincipals}
                        id="download-all"
                    />}

                    {/* Small Screen size icons */}
                    <Button
                        className="mr-1 d-lg-block d-xl-none"
                        icon="showDetail"
                        onClick={() => expandAllCards(principalCards)}
                        id="expand-all-labelless"
                    />
                    <Button
                        className="mr-1 d-lg-block d-xl-none"
                        icon="hideDetail"
                        onClick={() => collapseAllCards(principalCards)}
                        id="collapse-all-labelless"
                    />
                    {downloadButtonAvailable && <Button
                        className="mr-1 d-lg-block d-xl-none"
                        icon="download"
                        onClick={downloadPrincipals}
                        id="download-all-labelless"
                    />}

                    {/* Always displayed the same no matter the screen size */}
                    <BulkAction
                        principalArnsCount={selectedPrincipalCards.size}
                        bulkRegisterMfaModalHooks={bulkRegisterMfaModalHooks}
                    />
                </div>
            </div>
            <RenderPrincipalCards
                loading={getPrincipalScopesLoading || listPrincipalsLoading}
                errorMessage={errorMessage}
                principalCards={principalCards}
                toggleExpand={toggleExpand}
                toggleSelect={toggleSelect}
                registerMfaModalHooks={registerMfaModalHooks}
                removeScopeModalHooks={removeScopeModalHooks}
            />
            <RegisterMfaModal
                open={registerMfaModalOpen}
                setOpen={setRegisterMfaModalOpen}
                principalArn={registerMfaModalPrincipalArn!}
                setPrincipalArn={setRegisterMfaModalPrincipalArn}
                fullName={registerMfaModalFullName!}
                setFullName={setRegisterMfaModalFullName}
            />
            <RemoveScopeModal
                open={removeScopeModalOpen}
                setOpen={setRemoveScopeModalOpen}
                principalArn={removeScopeModalPrincipalArn!}
                setPrincipalArn={setRemoveScopeModalPrincipalArn}
                fullName={removeScopeModalFullName!}
                setFullName={setRemoveScopeModalFullName}
                tenantId={removeScopeModalTenantId!}
                setTenantId={setRemoveScopeModalTenantId}
                scope={removeScopeModalScope!}
                setScope={setRemoveScopeModalScope}
                userType={removeScopeModalUserType!}
                setUserType={setRemoveScopeModalUserType}
            />
            <BulkRegisterMfaModal
                open={bulkRegisterMfaModalOpen}
                setOpen={setBulkRegisterMfaModalOpen}
                principalCards={selectedPrincipalCards}
            />
        </div>
    )
}
